@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700);
* {
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

body {
  margin: 0;
  padding: 0;
}

.mr-20 {
  margin-right: 20px !important;
}

html, body, #app, #app>div, #root {
  background-color: #8fa2b2;
  height: 100%;
  overflow: auto;
}

.site-container {
  background-color: #f4f8f9!important;
  padding: 0;
  margin: 0;
}

.smartphone {
  border-radius: 12px;
  border: solid 6px #597892;
  max-width: 300px;
  max-height: 400px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.site-row{
  width: 100%;
  margin: 0;
}

.logo-site-small {
  width: 150px;
  margin-top: 20px;
}

.happy-dusty {
  max-width: 300px;
}

.image-site-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.images-site-small {
  max-width: 200px;
  padding: 2px;
}

.footer-container .list-group-item{
  background-color: rgb(96 101 105);
  color: white;
}

.centered-text{
  align-self: center;
  margin-bottom: 0px;
}

.site-text{
  font-weight: bold;
  color: #8fa2b2;
  font-size: 1.5em;
}

.custom-accordion {
  width: 80%;
  margin-top: 20px;
  margin-bottom: 150px;
}

.custom-accordion .card-header {
  background: #8fa2b2;
}

.custom-accordion .card-header button{
  font-size: 1em;
}

.card-header .btn-link:hover{
  color: white;
}

.card-header .btn-link{
  text-decoration: none!important;
}

.social-container{
  display: flex;
  justify-content: space-between;
  max-width: 150px;
  margin-bottom: 20px;
}

.social-container i{
  color: white;
  padding: 10px;
  cursor: pointer;
}

.footer-text{
  font-weight: bold;
  color: white;
  font-size: 1em;
  padding: 20px;
  padding-bottom: 0px;
}

.footer-text a{
  display: block;
}

.simple-footer-text{
  color: white;
  font-size: 16px;
  font-weight: normal;
}

.centered-cell{
  display: flex;
  justify-content: center;
  align-content: center;
}

.site-language {
  display: flex;
  justify-content: flex-end;
  align-content: center;
  padding: 10px;
}

.tab-container{
  width: 100%;
  background-color: #8fa2b2;
}

.logo-site {
  width: 300px;
}

.simple-text{
  color: #4a4545;
  font-size: 16px;
  font-weight: normal;
}

.site-image{
  max-width: 300px;
  max-height: 400px;
}

.list-image-container{
  max-width: 80px;
}

.city-info-label{
  font-size: 12px;
  margin-left: 20px;
  cursor: pointer;
}

.app-background{
    background-color: #bdc4ca!important;
}

.app-background div{
    background-color: #bdc4ca!important;
}

.white-modal {
  background-color: white!important;
}

.app_aside {
  background-color: white;
}

.title-bar-page{
  background-color: #8fa2b2;
}

.title-bar-page div{
  padding: 0px;
}

.info-list-container{
  padding: 0;
  margin: 0;
}

.info-list-container div{
  padding: 0;
  margin: 0;
}

.title-bar-page .title-text{
  padding: 20px!important;
}

.voyager-icon{
  color: #e17309;
}

.icon_pressed{
  color: #e17309;
}

.icon_unpressed{
  color: #8fa2b2;
}

.welcome-img{
  max-height: 498px;
}

.voyager-filter-btn{
  float: right;
  text-align: right;
  margin-top: 20px;
  margin-right: 10px;
  cursor: pointer;
}


.city-filter-btn{
  float: left;
  text-align: left;
  margin-top: 20px;
  margin-left: 10px;
  cursor: pointer;
}

.user-stat-container{
  width: 100%;
}

.user-ranking-container{
  float: left;
  width: 50%;
  margin-top: 6px;
  color: white;
  text-align: center;
}

.user-ranking-container i{
  margin-top: 1px;
  margin-right: 5px;
}


.user-ranking-container .user-ranking {
  font-size: 0.8em;
}

.user-points-container{
  float: left;
  width: 50%;
  color: white;
  font-size: 0.8em;
  text-align: center;
}


.user-points-container .user-points{
  margin-top: 11px;
}


.app_form {
  background-color: #8fa2b2;
  padding-top: 25px;
  padding-right: 40px;
  padding-left: 40px;
  overflow: auto;
}

.page_switcher {
  display: flex;
  justify-content: center;
  align-content: space-between;
  margin-bottom: 10%;
}

.page_switcher > div {
  flex: 1 1;
}

.page_switcher_container {
  display: flex;
  flex-direction: row;
}

.page_switcher_item {
  background-color: #bdc4ca;
  color: #494e54;
  padding: 10px 25px;
  cursor: pointer;
  font-size: .9em;
  border: none;
  outline: none;
  justify-content: center;
  text-decoration: none;
  padding-left: 16px;
}

.ranking-point-container{
  text-align: right;
}

.page_switcher_item--Active {
  background-color: #8fa2b2;
  color: white;
}

.page_switcher_item:first-child {
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}
.page_switcher_item:last-child {
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}

.form_center {
  margin-bottom: 100px;
}

.form_field {
  margin-bottom: 40px;
}

.form_field_label {
  text-transform: uppercase;
  font-size: .9em;
  color: black;
}

.form_field_input {
  background-color: transparent;
  border: none;
  color: rgb(12, 12, 12);
  outline: none;
  border-bottom: 1px solid #445366;
  font-size: 1em;
  font-weight: 300;
  padding-bottom: 10px;
  margin-top: 10px;
  border-radius: 0px;
}

.form_field_input:focus {
    color: black;
    background-color: transparent;
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid #445366;
}

.btn-primary:active{
  cursor: pointer;
}

.error-msg {
  color: #bb1717;
  font-weight: bold;
}

.success-msg {
  color: #9cc67e;
  font-weight: bold;
}

.form_field_input::-webkit-input-placeholder {
  color: #494e54;
}

.form_field_input:-ms-input-placeholder {
  color: #494e54;
}

.form_field_input::placeholder {
  color: #494e54;
}

.btn {
  color: white;
  border: none;
  outline: none;
  border-radius: 5px;
  font-size: .8em;
  font-weight: 500;
  margin-bottom: 2px;
}

.btn-primary {
  background-color: #9cc67e;
}

.btn-login{
  padding: 15px 20px;
  min-width: 150px;
}

.btn-secondary {
  background-color: #bdc4ca;
}

.btn-primary:hover, .btn-pressed {
  color: #fff;
  background-color: #e17209;
  border-color: #050505;
  box-shadow: 0 0 0 0.2rem rgb(183, 66, 0);
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #e17209;
  border-color: #050505;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgb(183, 66, 0);
}

.btn-primary.disabled, .btn-primary:disabled {
    background-color: #dcdcdc;
}

.add-button{
  position: fixed;
  bottom: 20px;
  right: 20px;
}

.html-button{
  position: fixed;
  bottom: 20px;
  right: 80px;
}

.form_title {
  color: #494e54;
  font-weight: 300;
  margin-bottom: 50px;
}

.form_title_link {
  color: #494e54;
  text-decoration: none;
  display: inline-block;
  font-size: 1.7em;
  margin: 0 10px;
  padding-bottom: 5px;
}

.form_title_link:first-child {
  margin-left: 0;
}

.form_title_link--Active {
  color: white;
  border-bottom: 1px solid #00afc2;
}

.form_field_link {
  color: #66707D;
  text-decoration: none;
  display: inline-block;
  border-bottom: 1.5px solid #225E62;
  padding-bottom: 5px;
  margin-top: 15px;
  cursor: pointer;
}

.form_field_checkbox_label {
  color: #646F7D;
  font-size: .9em;
}

.form_field_checkbox {
  position: relative;
  top: 1.5px;
}

.form_field_terms_link {
  color: white;
  border-bottom: 1px solid #00afc2;
  text-decoration: none;
  display: inline-block;
  padding-bottom: 2px;
  margin-left: 5px;
}

::-webkit-input-placeholder { /* Edge */
  color: #494e54;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #494e54;
}

::placeholder {
  color: #494e54;
}

a:hover {
    color: white;
}

.message-column{
  text-align: center;
}

.message-container{
  padding: 40px;
  width: 100%;
  float: right;
  background-color: white;
  border-radius: 50px;
  margin-bottom: 170px;
  border: solid 3px black;
}

.dusty_message{
  color: black!important;
  text-align: center
}

.link-button{
  padding: 10px;
  background-color: #8fa2b2;
  border-radius: 5px;
  border: solid 1px #777e84;
  color: white;
}

.maxHeight{
  height: 100%;
}

.profile-img{
  width: 40px;
  height: 40px;
  border-radius: 6px;
}

.list-img{
  width: 100%;
  width: 40px;
  height: 40px;
  margin-left: 20px;
  border-radius: 6px;
}

.row-link-container > i{
  margin-left: 20px;
}

.icon-img{
  width: 100%;
  max-width: 40px;
  max-height: 40px;
  border-radius: 6px;
}

.max-height{
  height: 100%!important;
}

.logo-container-top{
  margin-top: 50px;
}

.logo-container-bottom{
  margin-bottom: 50px;
}

.menu-container{
  margin: 0px;
  padding: 0px;
}

.no-space-container{
  margin: 0px;
  padding: 0px;
}

.no-space-container div{
  margin: 0px;
  padding: 0px;
}

.text-center{
  text-align: center;
}

.menu-logo{
  max-width: 100px;
}

.profile-user-info{
  margin-left: 10px;
}

.profile-image{
  width: 100%;
  border: solid 2px white;
  margin-bottom: 30px;
  margin-top: 30px;
  border-radius: 30px;
  max-width: 300px;
}

.adv-image{
  width: 100%;
  border: solid 2px white;
  margin-bottom: 30px;
  margin-top: 30px;
  border-radius: 30px;
  max-width: 500px;
}

.skin-image{
  width: 100%;
  border: solid 2px white;
  margin-bottom: 30px;
  margin-top: 30px;
  border-radius: 30px;
  max-width: 100px;
}

.texture-image{
  width: 100%;
  margin-bottom: 30px;
  margin-top: 30px;
  max-width: 100px;
}

.empty-image{
  border: solid 2px white;
  margin-bottom: 30px;
  margin-top: 30px;
  border-radius: 30px;
  min-width: 500px;
  min-height: 500px;
}


.empty-texture{
  border: solid 2px white;
  margin-bottom: 30px;
  margin-top: 30px;
  min-width: 500px;
  min-height: 500px;
}

.link:hover{
  color: blue;
}

.modal-message{
  padding: 10px!important;
}

.profile-user-label{
  color: #9cc67e;
  font-weight: 600;
  text-align: right;
}

.nav_menu{
  background-color: #bec5ca !important
}


.title-text{
  color: white;
  font-weight: 600;
}

.btn{
  cursor: pointer;
}

.button-modal-space{
  width: 33.33%;
  float: left;
}

.hidden{
  display: none;
}

.like-info{
  font-size: 0.8em;
  font-weight: bold;
  cursor: pointer;
}

.like-info-img {
  width: 40px;
  cursor: pointer;
}

.title-text-container{
  margin-top: 30px;
}

#user_dropdown_menu{
  background-color: transparent;
}

.hidden-filechooser input[type="file"] {
  opacity: 0;
  position: absolute;
  pointer-events: none;
  width: 1px;
  height: 1px;
}

.hidden-filechooser input[type="file"] + label {
  color: #9cc67e;
  cursor: pointer;
}


.container-profile-txt {
  position: relative;
  width: 60%;
  margin-left: 20%;
}

.attraction-lev-1{
  background-color: #6589b5!important;
}

.attraction-lev-2{
  background-color: #be9ee2!important;
}


.attraction-lev-3{
  background-color: #f3da60!important;
}

.marker-lev-1{
  border-radius: 50%;
  box-shadow: 0 0 0 5px #6589b5;
}

.marker-lev-2{
  border-radius: 50%;
  box-shadow: 0 0 0 5px #be9ee2;
}

.marker-lev-3{
  border-radius: 50%;
  box-shadow: 0 0 0 5px #f3da60;
}

.unlocked-attraction-lev-1{
  -webkit-filter: drop-shadow(0px 0px 10px #be9ee2);
  filter: drop-shadow(0px 0px 10px #be9ee2);
}

.unlocked-attraction-lev-2{
  -webkit-filter: drop-shadow(0px 0px 10px #be9ee2);
  filter: drop-shadow(0px 0px 10px #be9ee2);
}

.unlocked-attraction-lev-3{
  -webkit-filter: drop-shadow(0px 0px 10px #f3da60);
  filter: drop-shadow(0px 0px 10px #f3da60);
}

.locked-attraction{
  -webkit-filter: grayscale(50%);
          filter: grayscale(50%);
}

.locked-plate{
  -webkit-filter: grayscale(50%);
          filter: grayscale(50%);
}

.not-liked{
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
}

.modal-header{
  border-bottom: 0px;
}
.modal-footer{
  border-top: 0px;
}

.user-marker{
  border-radius: 50%;
}

.visit-date{
  font-size: 0.7em;
  color: black;
  float: right;
}

.discovery-wish-container{
  font-size: 1em;
}

.no-pointer{
  cursor: default!important;
}

.pointer{
  cursor: pointer!important;
}

.wish-label{
  padding: 5px;
  border-radius: 5px;
  font-size: 0.8em;
  color: #49841f;
}

.button-icon{
  margin-right: 10px;
}

.marker-new{
  border-radius: 50%;
  box-shadow: 0 0 0 5px gray;
}

.tab-content{
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
}

.map-style{
  height: 100%;
  width: 100%;
}

.tabs-item-menu a.active {
  background-color: white!important;
  color: #8fa2b2!important;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  border-bottom: 0px;
}

.tabs-item-menu .nav-item:first-child a.active {
  border-top-left-radius: 0px;
  border-left: 0px;
}

.tabs-item-menu .nav-item:last-child a.active {
  border-top-right-radius: 0px;
  border-right: 0px;
}

.tabs-item-menu a {
  background-color: #8fa2b2!important;
  color: white!important;
}

.tabs-item-menu .nav-item {
  width: 16.66%;
  text-align: center;
  border-radius: 0px!important;
}

.list-group-item{
  border: none;
  background-color: white;
  color: #bec5ca;
  line-height: 20px;
  border-radius: 0px!important;
  padding-left: 0px!important;
  padding-right: 0px!important;
}


.row-link-container:hover{
  background-color: #e17209!important;
  text-decoration: none;
  cursor: pointer;
}

.list-group{
  background-color: white;
  color: #565d63;
}

.manage-item-container{
  padding-top: 24px!important;
}

.manage-item-container .list-group-item{
  line-height: 40px;
}

.page-modal{
  width: 100%;
  height: 100%;
}

.locked-text{
  color: #8fa2b2!important;
}

.unlocked-text{
  color: #333436!important;
}

.infinite-scroll-container{
  margin: 0px!important;
  background-color: white;
}

.white-background{
  background-color: white!important;
}

.wished-check{
  margin-left: 20px;
  color: #9cc67e;
}

.wish-button-container{
  padding-top: 10px;
}

.user-like-page .modal-content{
  border-radius: 5px;
  border: solid 1px #8fa2b2;
}

.discovery-info span{
  cursor: pointer;
  color: #565d63;
}

.fake-page-modal .modal-dialog{
  width: 100%!important;
  height: 100%!important;
  margin: 0!important;
  max-width: 100%!important;

}

.fake-page-modal .modal-body{
  padding: 0!important;
  background-color: #8fa2b2;
}

.fake-page-modal .modal-content{
  height: 100%;
  background-color: #8fa2b2!important;
}

.welcome-page-modal .modal-dialog{
  max-width: 800px;
}

.notifications-readed{
  margin-left: 5px;
  font-weight: bold;
}

.notifications-to-read span{
    color: #f13434;
    padding-right: 5px;
    padding-left: 3px;
    margin-left: 5px;
    font-weight: bold;
}

.notification-date {
  font-size: 0.7em;
  margin-left: 20px;
}

.welcome-page-modal .modal-body{
  padding: 0!important;
}

.welcome-page-modal .modal-content{
  background-color: #8fa2b2!important;
}


.link-icon{
    margin-left: 20px;
    cursor: pointer;
}
.top-menu{
  justify-content: space-between;
}

.tab-menu{
  margin: 0px;
  height: 40px;
}
.tab-menu div{
  padding: 0px;
}

.tab-menu-content{
  background-color: white;
}

.raiting-number{
  float: right;
  font-size: 0.8em;
  margin-top: 4px;
}

.separator{
  width: 100%;
  margin-top: 24px!important;
}

.separator span{
  color: #8fa2b2;
  font-size: 0.8em;
}

.user-item-list{
  font-weight: bold;
}

.back-button{
  color: white;
  padding: 20px!important;
  background-color: #8fa2b2;
  cursor: pointer;
  float: left;
}

.row-link-container{
  border-radius: 5px;
  padding: 10px!important;
  color: #565d63;
  text-decoration: none;
}

.row-link-container:hover{
  color: white;
  text-decoration: none;

}

.row-link-profile{
  border-radius: 5px;
  padding: 10px!important;
  color: #565d63;
  text-decoration: none;
}

.row-link-profile:hover{
  color: black;
  text-decoration: none;

}

.dusty-helper-container{
  max-width: 150px;
  max-height: 150px;
  position: absolute;
  bottom: 25px;
  right: 10px;
  z-index: 1000;
  cursor: pointer;
}


.locator-button{
  max-width: 50px;
  max-height: 50px;
  position: absolute;
  bottom: 25px;
  left: 10px;
  z-index: 1000;
  cursor: pointer;
}

.tab-content-menu{
  width: 100%;
  height: 60px;
  background-color: white;
  border-bottom: solid 2px #8fa2b2;
}

.wd-tables-container thead{
  background-color: #bdc4ca;
}

.wd-tables-container tbody{
  background-color: white;
}

.wd-tables-container tbody td{
  font-size: 12px;
}

.buttons-container {
  padding: 5px!important;
}

.buttons-container button {
  margin-left: 10px;
}

.language-chooser{
  border-radius: 5px;
  float: left;
}



.language-chooser-site{
  border-radius: 5px;
  float: left;
  color: white;
  font-weight: bold;
  max-height: 25px;
  background-color: #8fa2b2;
}

.loader-container{
  position: fixed;
  top: 0;
  background-color: #bdc4cac7;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
  display: block;
}

.loader-container img{
  width: 20%;
  position: relative;
  top: 40%;
  left: 40%;
  z-index: 1001;
}
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

.city-title{
  color: #8fa2b2;
  font-size: 20px;
  margin-top: 15px;
  cursor: pointer;
  font-weight: 500;
  line-height: 55px;
}

.fake-modal-title{
  color: white;
  font-weight: 500;
  line-height: 65px;
  margin-right: 62px;
}


.dusty-menu{
  background-color: white;
  overflow: auto;
  border: solid 2px #8fa2b2;
  width: 60%;
  float: right;
  z-index: 1001;
  position: absolute;
  right: 25px;
  top: 70px;
  border-radius: 10px;
  max-width: 400px;
}


.dusty-buttons-container{
  padding: 10px!important;
}

.dusty-buttons-container button{
  margin-left: 10px!important;
  width: 40px;
  height: 40px;
  border-radius: 5px;
}

.dusty-menu-form-container{
  padding-left: 10px!important;
  padding-right: 10px!important;
  color: #8fa2b2;
}

.discovery-button-container button{
  font-size: 15px;
  background-color: #8fa2b2;
}

.modal-title{
  font-size: 1.2em;
}

.modal-close-button{
  left: 0;
  position: absolute;
  margin-left: 20px;
}

img.shake:hover {
  /* Start the shake animation and make the animation last for 0.5 seconds */
  -webkit-animation: shake 1s;
          animation: shake 1s;

  /* When the animation is finished, start again */
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}

@-webkit-keyframes shake {
  0% { -webkit-transform: translate(1px, 1px) rotate(0deg); transform: translate(1px, 1px) rotate(0deg); }
  10% { -webkit-transform: translate(-1px, -1px) rotate(-1deg); transform: translate(-1px, -1px) rotate(-1deg); }
  20% { -webkit-transform: translate(-1px, 0px) rotate(1deg); transform: translate(-1px, 0px) rotate(1deg); }
  30% { -webkit-transform: translate(1px, 1px) rotate(0deg); transform: translate(1px, 1px) rotate(0deg); }
  40% { -webkit-transform: translate(1px, -1px) rotate(1deg); transform: translate(1px, -1px) rotate(1deg); }
  50% { -webkit-transform: translate(-1px, 2px) rotate(-1deg); transform: translate(-1px, 2px) rotate(-1deg); }
  60% { -webkit-transform: translate(-1px, 1px) rotate(0deg); transform: translate(-1px, 1px) rotate(0deg); }
  70% { -webkit-transform: translate(1px, 1px) rotate(-1deg); transform: translate(1px, 1px) rotate(-1deg); }
  80% { -webkit-transform: translate(-1px, -1px) rotate(1deg); transform: translate(-1px, -1px) rotate(1deg); }
  90% { -webkit-transform: translate(1px, 1px) rotate(0deg); transform: translate(1px, 1px) rotate(0deg); }
  100% { -webkit-transform: translate(1px, -1px) rotate(-1deg); transform: translate(1px, -1px) rotate(-1deg); }
}

@keyframes shake {
  0% { -webkit-transform: translate(1px, 1px) rotate(0deg); transform: translate(1px, 1px) rotate(0deg); }
  10% { -webkit-transform: translate(-1px, -1px) rotate(-1deg); transform: translate(-1px, -1px) rotate(-1deg); }
  20% { -webkit-transform: translate(-1px, 0px) rotate(1deg); transform: translate(-1px, 0px) rotate(1deg); }
  30% { -webkit-transform: translate(1px, 1px) rotate(0deg); transform: translate(1px, 1px) rotate(0deg); }
  40% { -webkit-transform: translate(1px, -1px) rotate(1deg); transform: translate(1px, -1px) rotate(1deg); }
  50% { -webkit-transform: translate(-1px, 2px) rotate(-1deg); transform: translate(-1px, 2px) rotate(-1deg); }
  60% { -webkit-transform: translate(-1px, 1px) rotate(0deg); transform: translate(-1px, 1px) rotate(0deg); }
  70% { -webkit-transform: translate(1px, 1px) rotate(-1deg); transform: translate(1px, 1px) rotate(-1deg); }
  80% { -webkit-transform: translate(-1px, -1px) rotate(1deg); transform: translate(-1px, -1px) rotate(1deg); }
  90% { -webkit-transform: translate(1px, 1px) rotate(0deg); transform: translate(1px, 1px) rotate(0deg); }
  100% { -webkit-transform: translate(1px, -1px) rotate(-1deg); transform: translate(1px, -1px) rotate(-1deg); }
}


.attraction-image, .plate-image{
  max-height: 400px;
}

.modal-header, .modal-footer{
  background-color: #8fa2b2;
  color: white;
}

.attraction-description{
  margin-top: 10px;
}

.attraction-info{
  margin-top: 10px;
  float: right;
}

.modal-body{
  padding-left: 0px;
    padding-right: 0px;
}

.modal-title{
  width: 100%;
}

.attraction-title{
  width: 100%;
}

.attraction-info-container{
  width: 100%;
  float: left;
}

.attraction-date{
  font-size: 0.7em;
  width: 50%;
  float: left;
}

.attraction-rank{
  font-size: 1em;
  float: right;
  text-align: right;
}

.attraction-rank>div{
  float: right;
}

.unlocked-objectives-icon{
  color: #e17209;
  padding: 20px!important;
  cursor: pointer;
  float: right;
  min-width: 80px;
}

.locked-objectives-icon{
  color: white;
  padding: 20px!important;
  cursor: pointer;
  float: right;
  min-width: 80px;
}

.text-right{
  text-align: right;
}

.text-left{
  text-align: left;
}

.discovery-info{
  padding-right: 10px!important;
  padding-left: 10px!important;
}

.discovery-info span{
  font-size: 0.8em;
}

.level-text{
  width: 50%;
  float: right;
  text-align: right;
  font-size: 0.7em;
}



.level-dusty-img{
  width: 20px;
  margin-left: 5px;
}

.level-points{
  font-size: 0.7em;
  margin-left: 20px;
}


.attr-img{
  padding: 10px!important;
  width: 100px;
  float: left;
}

.attr-description{
  float: left;
}

.attr-img img{
  border-radius: 50%;
  width: 80px;
  height: 80px;
}


.discovery-img{
  width: 100%;
}

.discovery-box{
  border-radius: 0px;
  background-color: white;
  margin-bottom: 20px!important;
  border: solid 2px #8fa2b2;
  border-radius: 10px;
  margin-top: 10px!important;
}

.like-info-container {
    padding: 10px!important;
}



.discovery-text-container{
  background-color: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.discovery-footer-container{
  padding: 10px!important;
}

.discovery-text{
  padding: 10px!important;
  white-space: pre-line;
}

.dark_text{
  color: black!important;
}

.to-read{
  background-color: #e0e9f1;
}

.user-email{
  color: white;
  font-weight: bold;
  font-size: 1em;
}

.user-text-level-1{
  color: #f9f9f9;
  text-shadow: 2px 1px 12px #95efb5;
}

.user-icon-level-1{
  border-radius: 20%;
  box-shadow: 0 0 0 3px #95efb5;
}

.user-text-level-2{
  color: #f9f9f9;
  text-shadow: 2px 1px 12px #0dc3ec;
}

.user-icon-level-2{
  border-radius: 20%;
  box-shadow: 0 0 0 3px #0dc3ec;
}

.user-text-level-3{
  color: #f9f9f9;
  text-shadow: 2px 1px 12px #e28614;
}

.user-icon-level-3{
  border-radius: 20%;
  box-shadow: 0 0 0 3px #e28614;
}

.user-text-level-4{
  color: #f9f9f9;
  text-shadow: 2px 1px 12px #52fbcd;
}

.user-icon-level-4{
  border-radius: 20%;
  box-shadow: 0 0 0 3px #52fbcd;
}

.user-text-level-5{
  color: #f9f9f9;
  text-shadow: 2px 1px 12px #122bde;
}

.user-icon-level-5{
  border-radius: 20%;
  box-shadow: 0 0 0 3px #122bde;
}

.user-text-level-6{
  color: #f9f9f9;
  text-shadow: 2px 1px 12px #15b30a;
}

.user-icon-level-6{
  border-radius: 20%;
  box-shadow: 0 0 0 3px #15b30a;
}

.user-text-level-7{
  color: #f9f9f9;
  text-shadow: 2px 1px 12px #f3aedd;
}

.user-icon-level-7{
  border-radius: 20%;
  box-shadow: 0 0 0 3px #f3aedd;
}

.user-text-level-8{
  color: #f9f9f9;
  text-shadow: 2px 1px 12px #ef160b;
}

.user-icon-level-8{
  border-radius: 20%;
  box-shadow: 0 0 0 3px #ef160b;
}

.user-text-level-9{
  color: #f9f9f9;
  text-shadow: 2px 1px 12px #ad19d2;
}

.user-icon-level-9{
  border-radius: 20%;
  box-shadow: 0 0 0 3px #ad19d2;
}

.user-text-level-10{
  color: #f9f9f9;
  text-shadow: 2px 1px 12px #efd208;
}

.user-icon-level-10{
  border-radius: 20%;
  box-shadow: 0 0 0 3px #efd208;
}

.contact-container {
  padding: 10px;
  text-align: center;
}

.contact-container span {
  color: white;
}

/*loader*/
.scroll-loader{
  text-align: center;
  margin-top: 20px;
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #8fa2b2;
  opacity: 1;
  border-radius: 50%;
  -webkit-animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
          animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple.white div {
  border: 4px solid white;
}


.lds-ripple div:nth-child(2) {
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s;
}
@-webkit-keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

/*loader*/

@media screen and (max-width: 576px) {
  .profile-user-label{
    text-align: left;
  }
}


@media screen and (max-width: 800px) {
  .dusty-menu{
    width: 90%;
    max-width: none;
  }

  .logo-container-top{
    margin-top: 30px;
  }

  .logo-container-bottom{
    margin-bottom: 30px;
  }

  .centered-cell{
    margin-bottom: 25px;
  }

  .centered-text {
    padding: 20px;
  }
  
}


@media screen and (max-width: 1250px) {
  .btn-responsive-container {
    width: 100%important!;
    min-width: 100%!important;
  }
}

input::-webkit-input-placeholder {
  color: black;
  opacity: 1;
}

input:-ms-input-placeholder {
  color: black;
  opacity: 1;
}

input::placeholder {
  color: black;
  opacity: 1;
}

.wdl_app_form {
  background-color: #7566da;
  padding-top: 25px;
  padding-right: 40px;
  padding-left: 40px;
  overflow: auto;
}

.wdl-container {
  background-color: white!important;
  width: 100%;
  padding: 0;
  margin: 0;
}

.wdl-menu-logo{
  max-width: 40px;
}

.wdl_nav_menu {
  background-color: #7566da !important;
}

.wdl-title-text {
  font-size: 20px;
  color: white;
  margin-left: 10px;
  font-weight: bold;
}

.wdl-menu-item > a{
  color: white!important;
  font-size: 20px;
  font-weight: bold;
}

.wdl-list-container {
  padding: 10px!important;
  margin-top: 50px!important;
}

.wdl-company-header a, .wdl-company-header a:hover {
  color: black!important;
  text-decoration: none!important;
  font-weight: bold;
}

.wdl-company-header {
  text-align: center;
  margin-bottom: 20px;
}

.wdl-header-img {
  width: 100px;
  height: 100px;
}

.wdl-company-menu-item {
  width: 100%;
  height: 100%;
  text-align: center;
  border-radius: 15px;
  min-width: 200px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.wdl-company-menu-item a{
  color: white!important;
  text-decoration: none;
}

.wdl-menu-text {
  font-weight: bold;
  font-size: 20px;
}

.wdl-menu-img {
  width: 50px;
}

.wdl-menu-row > div.col{
  margin-bottom: 20px;
}

.wdl-durc-date {
  font-weight: 300;
  font-size: 0.8em;
  color: black;
  float: right;
}

.wdl-container input.form_field_input{
  color: black;
}

.wdl-container label.form_field_label{
  color: black;
}

.wdl-profile-image{
  width: 100%;
  border: solid 2px white;
  margin-bottom: 30px;
  margin-top: 30px;
  border-radius: 30px;
  max-width: 200px;
}

.wdl-title-bar-page{
  background-color: white;
}

.wdl-back-button{
  color: #7566da;
  padding: 20px!important;
  cursor: pointer;
  float: left;
}

.wdl-fake-modal-title{
  color: black;
  font-size: 1.3em;
  font-weight: 500;
  line-height: 65px;
  margin-right: 62px;
}

.wdl-loader-container{
  position: fixed;
  top: 0;
  background-color: #7566da;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
  display: block;
}

.wdl-loader-container img{
  width: 20%;
  position: relative;
  top: 40%;
  left: 40%;
  z-index: 1001;
}

.lds-ripple.wdl div {
  border: 4px solid #7566da;
}

.react-datepicker-wrapper input{
  background-color: transparent;
    border: none;
    color: black;
    outline: none;
    border-bottom: 1px solid #445366;
    font-size: 1em;
    font-weight: 300;
    padding-bottom: 10px;
    margin-top: 10px;
    border-radius: 0px;
}

.file-manager-container {
  margin-bottom: 10px;
}

.file-item-image {
  width: 70%;
  float: left;
  overflow-x: auto;
}

a.file-url:hover{
  color: #007bff;
}

a.file-url span{
  text-decoration: underline;
}

.file-item-buttons {
  width: 30%;
  float: left;
  text-align: right;
}

.file-name {
 font-size: 18px; 
}

.wdl.modal-header,.wdl.modal-footer {
  background-color: #ffffff;
  color: white;
}

.wdl-table a {
  text-decoration: underline!important;
  color: #007bff!important;
  font-weight: 500;
  cursor: pointer;
}

.wdl-table {
  margin-bottom: 40px;
}

.wdl-load-container {
  padding: 30px;
  margin: 20px;
  background-color: #f2f2f2;
  border-radius: 20px;
}

.wdl-unload-container {
  padding: 30px;
  margin: 20px;
  background-color: #f2f2f2;
  border-radius: 20px;
}

.rdrDefinedRangesWrapper {
  display: none;
}

.info-not-found-container {
  padding: 0px;
  padding-bottom: 60px;
}

.info-not-found-container span{
  font-size: 20px;
  font-weight: bold;
}

.wdl-total {
  border-top: solid 2px black;
}

.wdl-total span{
  font-weight: bold;
}

.wdl-container .success-msg {
  text-align: center;
}

.wdl-container .error-msg {
  text-align: center;
}

.wdl-container a:hover {
  color: #007bff;
}

.wdl-container a:hover.btn-primary{
  color: white;
}

.files {
  margin-top: 50px;
}

.wdl-contract {
  margin-top: 50px;
  margin-bottom: 50px;
}


.fa-file-pdf-o {
  color: red;
}

.fa-file-code-o {
  color: black;
}

.fa-file-pdf-o span, .fa-file-code-o span{
  color: black!important;
}

.wdl-title {
  color: black;
  font-size: 1.1em;
  font-weight: 400;
  line-height: 60px;
}

tr.wdl-selected-table {
  border-top: solid 100px white;
}

.wdl-search-container {
  padding: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #edebeb;
  border-radius: 10px;
  margin-left: 5px;
  margin-right: 5px;
}

.double-buttons-footer {
  justify-content: space-around;
}

.wdl-container .form_field_input {
  background-color: transparent;
  border: none;
  color: white;
  outline: none;
  border-bottom: 1px solid #445366;
  font-size: 1em;
  font-weight: 300;
  padding-bottom: 10px;
  margin-top: 10px;
  border-radius: 0px;
}

.bc_data{
  margin-top: 20px;
  margin-bottom: 60px;
  background-color: #f5f3f3;
  padding: 45px;
  border-radius: 10px;
}

.wdl-message-container {
  min-height: 40px;
}

.wdl-pallets-label {
  font-size: 10px;
  color: #7c7878;
}
